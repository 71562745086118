<template>
  <div class="shipments-page">
    <div class="flex items-center justify-between">
      <h2>{{$t('Packing Task Lists')}}</h2>
    </div>
    <shipblu-table
        pagination
        :data="pickingTaskList"
        :tableLoader="tableLoader"
      >
      <template slot="thead">
        <shipblu-th>{{$t('ID')}}</shipblu-th>
        <shipblu-th>{{$t('Created on')}}</shipblu-th>
        <shipblu-th>{{$t('Type')}}</shipblu-th>
        <shipblu-th>{{$t('SKU')}}</shipblu-th>
        <shipblu-th>{{$t('QTY')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
      </template>
      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <shipblu-td :data="data[indextr].id">
            {{ data[indextr].id }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].created">
            {{ common.splitYearOfDate(data[indextr].created) }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].type">
            {{ data[indextr].type }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].sku_count">
            {{ data[indextr].sku_count }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].products_in_orders_quantity">
            {{ data[indextr].products_in_orders_quantity }}
          </shipblu-td>

            <shipblu-td :data="data[indextr].status" :style="`color: ${getStyle(data[indextr])}`" style="white-space: nowrap;">
              <div class="m-auto flex items-center justify-center font-semibold">
                <div><span class="status-icon mr-1"></span></div>
                <span class="capitalize">{{ getStatusLabel(data[indextr]) }}</span>
              </div>
            </shipblu-td>

        </shipblu-tr>
      </template>
    </shipblu-table>
  </div>
</template>

<script>
import ShipbluTable from '../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import {sendFulfillmentRequest} from '../../http/axios/requestHelper.js'
import common  from '@/assets/utils/common'

export default {
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd
  },
  data () {
    return {
      pickingTaskList: [],
      tableLoader: false,
      common
    }
  },
  methods: {
    loadPackingTaskList () {
      this.tableLoader = true
      sendFulfillmentRequest(true, false, this, 'api/v1/fc/packing-tasklists/', 'get', null, true,
        (response) => {
          this.pickingTaskList = response.data.results
          this.tableLoader = false
        }
      )
    },
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(order.status)
    }
  },
  created () {
    this.loadPackingTaskList()
  }
}
</script>
<style lang="scss">
</style>